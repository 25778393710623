<template>
    <el-dialog :title="title"
               :visible.sync="dialogFormVisible"
               width="500px"
               @close="close">
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
            <el-form-item label="轮播名称" prop="name">
                <el-input v-model.trim="form.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="是否禁用" prop="isDisable">
                <el-switch v-model="form.isDisable"></el-switch>
            </el-form-item>
            <el-form-item label="轮播图片" prop="url">
                <el-button type="primary" @click="uploadShow({ key: 'value' })">
                    上传轮播图片
                </el-button>
            </el-form-item>
            <el-form-item>
                <img class="img" :src="form.url" />
                <vab-upload ref="vabUpload"
                            title="上传轮播图片"
                            action="/File/UpLoad"
                            name="file"
                            accept="image/*"
                            :limit="1"
                            :size="1"
                            v-on:finish="upload"></vab-upload>
            </el-form-item>


        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" @click="save">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import { addCarousel, editCarousel } from '@/api/carousel';
    import VabUpload from '@/components/VabUpload';

    export default {
        name: 'CarouselEdit',
        components: { VabUpload },
        data() {
            return {
                form: {
                    id: 0,
                    name: '',
                    isDisable: false,
                    url: ''
                },
                rules: {
                    name: [{ required: true, trigger: 'blur', message: '请输入轮播名称' }],
                    url: [{ required: true, trigger: 'blur', message: '请上传轮播图标' }],
                },
                title: '',
                dialogFormVisible: false,
            }
        },

        methods: {
            uploadShow(data) {
                this.$refs['vabUpload'].handleShow(data);
            },
            upload: function (data) {
                if (data.length > 0) {
                    this.form.url = data[0].url;
                }
            },
            showEdit(row) {
                if (!row) {
                    this.title = '添加'
                } else {
                    this.title = '编辑';
                    this.form = Object.assign({}, row);
                }
                this.dialogFormVisible = true;
            },
            close() {
                this.$refs['form'].resetFields()
                this.form = this.$options.data().form
                this.dialogFormVisible = false
            },
            save() {
                this.$refs['form'].validate(async (valid) => {
                    if (!valid) return false;
                    if (this.form.id === 0) {
                        const { isSucceed, message } = await addCarousel(this.form)
                        this.$baseMessage(message, (isSucceed ? 'success' : 'error'));
                        if (isSucceed) this.close();
                    } else {
                        const { isSucceed, message } = await editCarousel(this.form)
                        this.$baseMessage(message, (isSucceed ? 'success' : 'error'));
                        if (isSucceed) this.close();
                    }
                    this.$emit('fetch-data');
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .img {
        width: 100%;
        max-width: 200px;
    }
</style>
