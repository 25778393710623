<template>
  <el-dialog
    :modal-append-to-body='false'
    :before-close="handleClose"
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="uploadDialogFormVisible">
      <div class="upload">
          <el-alert type="success" :closable="false" :title="`支持jpg、jpeg、png格式，最多选择${limit}张图片，每张不可大于${size}M`"></el-alert>
          <br />

          <el-progress  :percentage="percentage" v-if="show"></el-progress>

          <!--<el-alert type="success" v-if="show" :title="`上传中...`"></el-alert>-->
          <br />

          <el-upload ref="upload" 
                     :action="url"
                     :auto-upload="false"
                     :close-on-click-modal="false"
                     :data="data"
                     :file-list="fileList"
                     :headers="headers"
                     :limit="limit"
                     :multiple="true"
                     :name="name"
                     :on-change="handleChange"
                     :on-error="handleError"
                     :on-exceed="handleExceed"
                     :on-preview="handlePreview"
                     :on-progress="handleProgress"
                     :on-remove="handleRemove"
                     :on-success="handleSuccess"
                     :accept="accept"
                     class="upload-content"
                     list-type="picture-card"
                     >

              <i slot="trigger" class="el-icon-plus"></i>


              <el-dialog :visible.sync="dialogVisible" append-to-body title="查看大图">
                  <div>
                      <img :src="dialogImageUrl" alt="" width="100%" />
                  </div>
              </el-dialog>
          </el-upload>
      </div>
      <div slot="footer"
           class="dialog-footer"
           style="position: relative; text-align: right">



          <el-button :loading="loading"
                     size="small"
                     style="margin-left: 10px"
                     type="success"
                     @click="submitUpload">
              上传
          </el-button>

          <el-button type="primary" @click="save">确定</el-button>
      </div>
  </el-dialog>
</template>

<script>
    import { uploadUrl } from '@/config/upload.config.js';
    import { getAccessToken } from '@/utils/accessToken'
    export default {
        name: 'VabUpload',
        props: {
            action: {
                type: String,
                default: '',
                required: true,
            },
            name: {
                type: String,
                default: 'file',
                required: true,
            },
            limit: {
                type: Number,
                default: 50,
                required: true,
            },
            size: {
                type: Number,
                default: 1,
                required: true,
            },
            accept: {
                type: String,
                default: 'image/png,image/jpeg',
                required: true,
            },
            title: {
                type: String,
                default: "附件上传"
            }
        },
        data() {
            return {
                show: false,
                loading: false,
                dialogVisible: false,
                dialogImageUrl: '',
                fileList: [],
                picture: 'picture',
                typeList: null,
                uploadDialogFormVisible: false,
                data: {},
                percentage: 0,
                succeedFiles: [],
                headers: { Authorization: getAccessToken() }
            }
        },
        computed: {
            url: function () {
                let u = uploadUrl + this.action;
                console.log(u);
                return u;
            },
        },
        methods: {
            submitUpload() {
                this.$refs.upload.submit();
            },
            handleProgress(event, file, fileList) {
                this.loading = true;//上传按钮 Loading
                this.show = true;   //进度显示
                this.percentage = parseInt((event.loaded / event.total * 100).toFixed(0));
            },
            handleChange(file, fileList) {
                if (file.size > 1048576 * this.size) {
                    fileList.map((item, index) => { if (item === file) fileList.splice(index, 1) });
                    this.fileList = fileList;
                }
            },
            handleSuccess(response, file, fileList) {
                if (response.isSucceed && response.result.length > 0) this.succeedFiles.push(response.result[0]);
                setTimeout(() => {
                    this.loading = false;
                    this.show = false;
                }, 2000);

            },
            handleError(err, file, fileList) {
                this.loading = false;
                this.show = false;
            },
            handleRemove(file, fileList) {
                this.succeedFiles = this.succeedFiles.filter((item) => {
                    return item.name != file.name;
                });
            },
            handlePreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            handleExceed(files, fileList) {
                this.$baseMessage(`当前限制选择${this.limit}个文件,本次选择了${files.length}个文件`, 'error');
            },
            handleShow(data) {
                this.succeedFiles = [];
                this.data = data;
                this.uploadDialogFormVisible = true;
            },
            handleClose() {
                this.fileList = [];
                this.picture = 'picture';
                this.uploadDialogFormVisible = false;
            },
            save() {
                /* 关闭窗口并向父组件返回上传成功的图片数组 */
                //console.log(JSON.stringify(this.succeedFiles));
                this.handleClose();
                this.$emit('finish', this.succeedFiles);
            }
        },
    }
</script>

<style lang="scss" scoped>
  .upload {
    height: 500px;

    .upload-content {
      .el-upload__tip {
        display: block;
        height: 30px;
        line-height: 30px;
      }

      ::v-deep {
        .el-upload--picture-card {
          width: 128px;
          height: 128px;
          margin: 3px 8px 8px 8px;
          border: 2px dashed #c0ccda;
        }

        .el-upload-list--picture {
          margin-bottom: 20px;
        }

        .el-upload-list--picture-card {
          .el-upload-list__item {
            width: 128px;
            height: 128px;
            margin: 3px 8px 8px 8px;
          }
        }
      }
    }
  }
</style>
