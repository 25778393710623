import request from '@/network/request';


/**
 * ��ѯ�б�
 */
export async function GetCarousels() {
    return request.get('/Carousel/Get');
}


/**
 * ��ѯ����
 */
export async function GetCarouselById(data) {
    return request.get('/Carousel/GetById', data);
}


/**
 * ��ѯ��ҳ
{
  "isDisable": true,
  "name": "string",
  "pageSize": 0,
  "pageIndex": 0
}
 */
export async function GetCarouselsByPage(data) {
    return request.post('/Carousel/GetByPage', data);
}

/**
 * ����
 * @param {any} data
 * 
{
  "name": "string",
  "url": "string",
  "isDisable": true
}
 */
export async function addCarousel(data) {
    return request.post('/Carousel/Add', data);
}


/**
 * �༭ְҵ����
 * @param {any} data
{
  "id": 0,
  "name": "string",
  "url": "string",
  "isDisable": true
}
 */
export async function editCarousel(data) {
    return request.post('/Carousel/Edit', data);
}